<template>
    <v-main>
        <link href="https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap" rel="stylesheet">
        
        <v-container>
        <h1 class="text-center">SF ELEC</h1>
        <h4 class="text-center">Électricité Générale</h4>
            <v-row
            no-gutters
            class='flex-column flex-md-row'
            >
                <v-col
                md="5"
                class="ml-auto"
                >
                    <v-row>
                        <h2>Le métier d'électricien</h2>
                        <br>                    
                        Son choix se porte finalement dans le résidentiel. Plusieurs aspects de ce secteur se démarquent:
                        <br><br>
                    </v-row>
                    <!-- <div class="p2"> -->
                    <v-row
                    align="center">
                        
                        <v-col
                        md="4"
                        cols="12"
                        class = 'text-center'
                        >                        
                        <img
                            :src="require('.././assets/boite.svg')"
                            contain
                            height="100"
                        />
                        </v-col>
                        <v-col
                        md="8"
                        >         
                        "<span class='txt-important'>Le dépannage</span> peu parfois être très laborieux mais est quelque chose de stimulant. Rechercher l'erreur et établir un diagnostique est souvent singulier et propre à l'installation".
                        </v-col>
                    </v-row>

                    <br>
                    <!-- mais aussi: -->
                    <br>

                    <v-row
                    align="center">
                        <v-col
                        md="4"
                        cols="12"
                        align="center"
                        class = 'text-center'
                        >
                            <img
                                :src="require('.././assets/document.svg')"
                                contain
                                height="100"
                            />
                        </v-col>
                        <v-col
                        md="8">
                            "<span class='txt-important'>La remise aux normes</span> d'une installation peut être compliquée et coûteuse dans certains cas mais est nécessaire. De plus, il y a réellement un aspect gratifiant de sécuriser l'habitation des clients".
                        </v-col>
                    </v-row>

                    <br>
                    <!-- Ou encore: -->
                    <br>

                    <v-row
                    align="center">
                        <v-col
                        md="4"
                        cols="12"
                        class = 'text-center'

                        >
                            <img
                                :src="require('.././assets/house.svg')"
                                contain
                                height="100"
                            />
                        </v-col>
                        <v-col
                        md="8">
                            "<span class='txt-important'>L'installation</span> de matériel est souvent la partie la plus facile de ce métier mais cela consiste tout de même à le faire dans les règles de l'art. Il est très important, pour leur longévité ainsi que pour la sécurité des utilisateurs, d'installer correctement tout matériel électrique".
                        </v-col>
                    </v-row>
                    <!-- </div> -->
                </v-col>

            <br>
            <v-spacer></v-spacer>
        
                <v-col
                md="5"
                class="ml-auto"
                >
                    <div class="p3">
                    <h2>Objectif</h2>
                    <br>
                    L'objectif de cette entreprise est simple : proposer <span class='txt-important'>un service de qualité</span> garantissant à tous ses clients <span class='txt-important'>sécurité et fiabilité</span>.
                    </div>

                    <br>

                    <div class="p1">
                    <h2>Création</h2>
                    <br>
                    SF ELEC est une entreprise créé par Fortuny Sébastien en janvier 2020 en tant que personne physique. 
                    <br>
                    "Lors de mon stage en entreprise, j'ai vite constaté qu'en plus d'être en pénurie, le métier d'électricien offrait un panel de possibilités et que le besoin de personnes qualifiées dans le secteur n'allait faire que croître".
                    </div>
                    <br>            
                </v-col>            
            </v-row>   
        </v-container>     
    </v-main>
</template>

<script>
export default {
    
}
</script>

<style scoped>
div{
    text-align: justify;
}

.txt-important{
    color:cornflowerblue;
    font-weight: bold;
}

h1, h2{
    font-family: 'Jura', sans-serif;
    font-weight: bold;  
    color:#3D6F9D;
}

h1{
    margin-top: 20px;
}

h4{
    margin-bottom: 30px;
    font-family: 'Jura', sans-serif;
}

@media (max-width: 959px) {
    h1{
        margin-top: 70px;
    }   
}
</style>